import React from "react";
import styles from "./styles.module.scss";

interface FooterTextProps {
	className?: string;
}
const FooterText: React.FC<FooterTextProps> = ({ className }) => {
	return (
		<>
			<p className={`${className} ${styles.footer}`}>
				©2023 HalaTechAI. All rights reserved.
			</p>
		</>
	);
};

export { FooterText };
